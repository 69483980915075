import { connect } from 'react-redux';
import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as actions from '../actions';
import Login from '../components/Login';
import CustomToast from '../../../utils/CustomToast';

const login = async (username, password, keepLogin) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_ORIGIN}/auth/login`, {
      username,
      password,
    });
    const { token, user } = data;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (keepLogin) {
      localStorage.setItem('token', token);
    }
    return user;
  } catch (err) {
    throw err;
  }
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  login: (username, password, keepLogin) => {
    login(username, password, keepLogin)
      .then(res => {
        dispatch(actions.setLogin(res));
      })
      .catch(ex => {
        // dispatch(actions.setLogin(false));
        if (ex.response && ex.response.data && ex.response.data.message) {
          toast(<CustomToast content={ex.response.data.message} type="error" />, {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.ERROR,
          });
        } else {
          toast(<CustomToast content="Username or password incorrect." type="error" />, {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.ERROR,
          });
        }
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    dispatch(actions.setLogin(false));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login),
);
