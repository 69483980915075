import { connect } from "react-redux";
import axios from "axios";
import * as actions from "../actions";
import FaqAdminAdd from "../components/FaqAdminAdd";

const mapStateToProps = state => {
  return {
    faqItems: state.faq.faqItems
  };
};

const addFAQ = async faq => {
  const { priority, question, answer } = faq;
  const { data } = await axios.post(`${process.env.REACT_APP_API_ORIGIN}/faq`, {
    priority,
    question,
    answer
  });
  return data;
};

const mapDispatchToProps = dispatch => ({
  addFAQ: faq => {
    addFAQ(faq)
      .then(res => {
        dispatch(actions.addFaq(res));
      })
      .catch(err => console.error(err));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqAdminAdd);
