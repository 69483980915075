import React from "react";
import Media from "react-media";
import { Row, Col } from "react-grid-system";
import Heading from "../components/Heading";

const Header = ({ title, style, children }) => (
  <Media query="(max-width: 768px)">
    {match => (
      <Row justify="between" style={{ width: "100%", margin: 0, ...style }}>
        <Col sm={12} md={6} style={{ padding: 0 }}>
          <Heading>{title}</Heading>
        </Col>
        <Col
          md={6}
          sm={12}
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            marginTop: match ? "10px" : "0px",
            justifyContent: match ? "flex-start" : "flex-end"
          }}
        >
          {children}
        </Col>
      </Row>
    )}
  </Media>
);

export default Header;
