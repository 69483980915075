import React from "react";

const CustomToast = ({ content, type }) => {
  return [
    <div style={iconBox(type)} key="1">
      <i
        className={`la ${
          type === "success" ? "la-check-circle-o" : "la-times-circle-o"
        }`}
        style={{
          fontSize: "30px",
          textAlign: "center",
          color: "white"
        }}
      />
    </div>,
    <div style={contentBox} key="2">
      {content}
    </div>
  ];
};

const iconBox = type => ({
  backgroundColor:
    type === "success" ? "#63C854" : type === "error" ? "#e74c3c" : "#FF5630",
  borderRadius: "4px",
  color: "white",
  flexShrink: 0,
  overflow: "hidden",
  textAlign: "center",
  width: "30px",
  height: "30px"
});

const contentBox = {
  flexGrow: "1",
  fontSize: "14px",
  padding: "5px",
  marginLeft: "5px"
};

export default CustomToast;
