import React from "react";
import Button from "../../../components/Button";

export default class FaqAdd extends React.PureComponent {
  state = {
    add: false,
    question: "",
    answer: ""
  };

  onClick = add => {
    this.setState({
      add
    });
  };

  onClickCancel = () => {
    this.setState({
      question: "",
      answer: ""
    });
    this.onClick(false);
  };

  onClickSave = () => {
    const { question, answer } = this.state;
    const { addFAQ } = this.props;
    if (question !== "" && answer !== "") {
      addFAQ({
        priority: 0,
        question,
        answer
      });
      this.setState({
        question: "",
        answer: ""
      });
    }
    this.onClick(false);
  };

  onChangeQuestion = e => {
    this.setState({
      question: e.target.value
    });
  };

  onChangeAnswer = e => {
    this.setState({
      answer: e.target.value
    });
  };

  render() {
    const { add, question, answer } = this.state;
    return add ? (
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #C6CBD4",
          padding: "20px 0px",
          transition: "height 1s ease-in-out",
          backgroundColor: "rgba(200, 215, 224, 0.3)",
          borderLeft: "4px solid #5381AC"
        }}
      >
        <input
          placeholder="Question"
          value={question}
          onChange={this.onChangeQuestion}
          style={{
            margin: "16px 0px 0px 36px",
            paddingLeft: "5px",
            width: "80%",
            height: "30px",
            fontWeight: "bold",
            fontSize: "14px",
            display: "block"
          }}
        />
        <textarea
          placeholder="Your answer here..."
          value={answer}
          onChange={this.onChangeAnswer}
          rows="6"
          style={{
            margin: "20px 0px 0px 36px",
            border: "2px solid rgb(238, 238, 238)",
            paddingLeft: "5px",
            width: "80%",
            fontSize: "14px",
            display: "block"
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "20px 0px 0px 36px"
          }}
        >
          <Button onClick={this.onClickSave}>Save FAQ</Button>
          <Button
            onClick={this.onClickCancel}
            style={{
              background: "lightgrey",
              marginLeft: "20px"
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    ) : (
      <div
        style={{
          width: "100%",
          paddingBottom: "20px",
          marginTop: "20px",
          cursor: "pointer"
        }}
      >
        <Button onClick={() => this.onClick(true)}>Add FAQ</Button>
      </div>
    );
  }
}
