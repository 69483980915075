import { connect } from "react-redux";
import axios from "axios";
import * as actions from "../actions";
import FaqAdminItem from "../components/FaqAdminItem";

const mapStateToProps = state => {
  return {
    faqItems: state.faq.faqItems
  };
};

const editFaq = async (faq, id) => {
  const { priority, question, answer } = faq;
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_ORIGIN}/faq/${id}`,
    {
      priority,
      question,
      answer
    }
  );
  return data;
};

const mapDispatchToProps = dispatch => ({
  editFAQ: (faq, id) => {
    editFaq(faq, id)
      .then(res => {
        dispatch(actions.editFaq(res));
      })
      .catch(err => console.error(err));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqAdminItem);
