import React from 'react';
import Media from 'react-media';
import SideMenu from './SideMenu/SideMenuContainer';
import Spinner from '../components/Spinner';

const ParentContainer = ({ children, modalOpen }) => (
  <Media query="(max-width: 699px)">
    {match => (
      <div>
        <div
          style={{
            width: '100wh',
            minHeight: '100vh',
            position: modalOpen ? 'fixed' : '',
            background: '#c8d7e0',
            marginLeft: match ? '0px' : '70px',
            marginTop: match ? '78px' : '0px',
            padding: match ? '10px 10px' : '40px 40px',
            fontFamily: 'Arial',
          }}
        >
          <SideMenu />
          {children}
        </div>
      </div>
    )}
  </Media>
);

export default ParentContainer;
