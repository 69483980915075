import React from 'react';
import classes from './Heading.module.scss';

const Heading = ({ children, style, className }) => (
  <h2 className={`${classes.text} ${className}`} style={{ ...style }}>
    {children}
  </h2>
);

export default Heading;
