import * as actionTypes from './actionTypes';

const initialState = {
  total: {
    projects: 0,
    db1: 0,
  },
  applications: [],
  brands: [],
  statuses: [],
  types: [],
  competitors: [],
  tasks: [],
  funnel: [],
  todos: [],
  entities: [],
  brandSupport: [],
  filter: {
    applications: [],
    users: [],
    regions: [],
    subregions: [],
    countries: [],
    years: [],
    planned: undefined,
    entities: [],
    brands: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REPORTS:
      // eslint-disable-next-line no-case-declarations
      const {
        applications,
        brands,
        total,
        statuses,
        types,
        competitors,
        tasks,
        funnel,
        todos,
        entities,
        brandSupport,
      } = action;
      return {
        ...state,
        total,
        applications,
        brands,
        statuses,
        types,
        competitors,
        tasks,
        funnel,
        todos,
        entities,
        brandSupport,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filter: {
          applications: action.applications,
          users: action.users,
          regions: action.regions,
          subregions: action.subregions,
          countries: action.countries,
          years: action.years,
          planned: action.planned,
          entities: action.entities,
          brands: action.brands,
        },
      };
    default:
      return state;
  }
};
