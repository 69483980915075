import React from "react";

const PortfoliosContainer = React.lazy(() => import("./PortfoliosContainer"));
const PortfolioContainer = React.lazy(() => import("./PortfolioContainer"));
const EditPortfolioContainer = React.lazy(() =>
  import("./EditPortfolioContainer")
);
const AddPortfolioContainer = React.lazy(() =>
  import("./AddPortfolioContainer")
);

export {
  PortfoliosContainer,
  PortfolioContainer,
  EditPortfolioContainer,
  AddPortfolioContainer
};
