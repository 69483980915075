// @flow
import React from 'react';
import classes from './Button.module.scss';

type Props = {
  style: Object,
  className: string,
  children: string,
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
};

const Button = (props: Props) => {
  const { className, style, children, onClick, ...rest } = props;
  return (
    <button
      onClick={onClick}
      className={`${classes.button} ${className}`}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
