import * as actionTypes from "./actionTypes";

export const helloWorld = value => {
  return { type: actionTypes.HELLO_WORLD, value };
};

export const setToken = token => {
  return { type: actionTypes.SET_TOKEN, token };
};

export const setLogin = loginData => {
  return { type: actionTypes.SET_LOGIN, loginData };
};
