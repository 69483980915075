export const SET_DATA = 'SET_DATA';
export const ADD_DATA = 'ADD_DATA';
export const PATCH_DATA = 'PATCH_DATA';
export const REMOVE_DATA = 'REMOVE_DATA';
export const SET_TYPE = 'SET_DATA_TYPE';
export const SET_REGIONS = 'SET_DATA_REGIONS';
export const SET_SUBREGIONS = 'SET_DATA_SUBREGIONS';
export const SET_COUNTRIES = 'SET_DATA_COUNTRIES';
export const SET_ALL_BRANDS = 'SET_ALL_DATA_BRANDS';
export const SET_BRANDS = 'SET_DATA_BRANDS';
export const SET_APPLICATIONS = 'SET_DATA_APPLICATIONS';
export const SET_COMPETITORS = 'SET_DATA_COMPETITORS';
export const SET_PROJECT_STATUSES = 'SET_DATA_PROJECT_STATUSES';
export const SELECT_CHANGE = 'SELECT_DATA_CHANGE';
export const EDIT_CHANGE = 'EDIT_DATA_CHANGE';
export const SET_TEAMLEADS = 'SET_DATA_TEAMLEADS';
export const SET_STRATEGIC_PROJECTS = 'SET_DATA_STRATEGIC_PROJECTS';
export const SET_USERS = 'SET_DATA_USERS';
export const SET_BUSINESS_UNITS = 'SET_BUSINESS_UNITS';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_PREDEFINED_TASKS = 'SET_PREDEFINED_TASKS';
export const SET_PRIORITIES = 'SET_PRIORITIES';
export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS';
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_CUSTOMER_SEGMENTATIONS = 'SET_CUSTOMER_SEGMENTATIONS';
export const SET_STATUS_REASONS = 'SET_STATUS_REASONS';
export const SET_PROJECTS = 'SET_OPERATIVE_PROJECTS';
export const SET_STRATEGIC_PROJECTS_PM = 'SET_STRATEGIC_PROJECTS_PM';
export const SET_STRATEGIC_PROJECT_TYPES = 'SET_STRATEGIC_PROJECT_TYPES';
export const SET_STRATEGIC_PROJECT_STATUSES = 'SET_STRATEGIC_PROJECT_STATUSES';
