import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { registerLocale } from 'react-datepicker';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-extraneous-dependencies
import enGB from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import './css';
import './line-awesome/line-awesome.min.css';

import ScrollToTop from './utils/ScrollToTop';

import Routing from './utils/Routing/RoutingContainer';
import rootReducer from './rootReducer';

import registerServiceWorker from './registerServiceWorker';
import { setLogin } from './pages/login/actions';

registerLocale('en-GB', enGB);

async function checkToken() {
  const token = localStorage.getItem('token');
  if (!token) {
    throw Error('No token present');
  }
  try {
    // Set auth header as default
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { data } = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/auth`);
    return data;
  } catch (err) {
    throw err;
  }
}

const appVersion = process.env.REACT_APP_VERSION || 'developmentVersion';
const clientVersion = localStorage.getItem('app-version');
if (appVersion !== clientVersion) {
  localStorage.clear();
  localStorage.setItem('app-version', appVersion);
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

checkToken()
  .then(res => {
    store.dispatch(setLogin(res));
  })
  .catch(err => console.error(err))
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Routing />
          </ScrollToTop>
        </Router>
      </Provider>,
      document.getElementById('root'),
    );
    registerServiceWorker();
  });
