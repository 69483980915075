import * as actionTypes from './actionTypes';

const defaultSelectValues = {
  businessUnits: [],
};
const initialState = {
  businessUnits: [],
  search: '',
  count: 0,
  page: 0,
  portfolio: {
    id: '',
    name: '',
    users: [],
    projects: [],
  },
  milestones: [],
  selectValues: defaultSelectValues,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CHANGE: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues[action.key] = action.value;
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.ADD_BUSINESS_UNIT:
      return { ...state, businessUnits: [...state.businessUnits, action.businessUnit] };
    case actionTypes.SET_BUSINESS_UNIT:
      return { ...state, businessUnits: action.businessUnits };
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.search };
    case actionTypes.SET_BUSINESS_UNITS:
      return { ...state, businessUnits: action.businessUnits };
    case actionTypes.RESET_FORM:
      return { ...state, selectValues: defaultSelectValues };
    case actionTypes.SET_COUNT:
      return { ...state, count: action.count };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.page };
    case actionTypes.SET_MILESTONES: {
      const { projectId, milestones } = action;
      return {
        ...state,
        milestones: [...state.milestones, { projectId, milestones }],
      };
    }
    default:
      return state;
  }
};
