export const SET_BUSINESS_UNITS = 'SET_BUSINESS_UNITS';
export const SET_BUSINESS_UNIT = 'SET_BUSINESS_UNIT';
export const ADD_BUSINESS_UNIT = 'ADD_BUSINESS_UNIT';
export const EDIT_BUSINESS_UNIT = 'EDIT_BUSINESS_UNIT';
export const SET_SEARCH = 'SET_BUSINESS_UNITS_SEARCH';
export const SET_COUNT = 'SET_BUSINESS_UNITS_COUNT';
export const SET_PAGE = 'SET_BUSINESS_UNITS_PAGE';
export const RESET_FORM = 'RESET_BUSINESS_UNITS_FORM';
export const SELECT_CHANGE = 'SELECT_BUSINESS_UNITS_CHANGE';
export const SET_MILESTONES = 'SET_BUSINESS_UNITS_MILESTONES';
