import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PasswordInfo from './PasswordInfo';
import CustomToast from '../../../utils/CustomToast';
import Button from '../../../components/Button';
import ProgressBar from './ProgressBar';

class Auth extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    invalidReason: '',
  };

  onChangePassword = e => {
    const { invalidReason } = this.state;
    if (invalidReason && e.target.value.length >= 8) {
      this.setState({
        invalidReason: '',
      });
    }
    this.setState({
      password: e.target.value,
    });
  };

  onChangeConfirmPassword = e => {
    const { invalidReason } = this.state;
    if (invalidReason && e.target.value.length >= 8) {
      this.setState({
        invalidReason: '',
      });
    }
    this.setState({
      confirmPassword: e.target.value,
    });
  };

  testPassword = password => {
    const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
    return regex.test(password);
  };

  setPassword = e => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;
    const { match, reset, history } = this.props;
    if (password !== confirmPassword) {
      this.setState({
        password: '',
        confirmPassword: '',
      });
      toast(<CustomToast content="Passwords do not match." type="error" />, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: toast.TYPE.ERROR,
      });
      return;
    }
    if (!this.testPassword(password)) {
      toast(
        <CustomToast
          content="Password must be at least 8 characters long and contain at least one special, numeric and uppercase character."
          type="error"
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.ERROR,
        },
      );
      this.setState({
        password: '',
        confirmPassword: '',
      });
      return;
    }

    const { token } = match.params;
    axios
      .put(
        `${process.env.REACT_APP_API_ORIGIN}/accounts/${reset ? 'change-password' : 'activate'}`,
        {
          token,
          newPassword: password,
        },
      )
      .then(() => {
        this.setState({
          password: '',
          invalidReason: '',
          confirmPassword: '',
        });
        toast(
          <CustomToast content="Password successfully updated. Redirecting..." type="success" />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.SUCCESS,
          },
        );
        setTimeout(() => {
          history.push({ pathname: '/login' });
        }, 3000);
      })
      .catch(({ response }) => {
        toast(
          <CustomToast
            content={response && response.data ? response.data.message : 'Error setting password.'}
            type="error"
          />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.ERROR,
          },
        );
        this.setState({
          password: '',
          confirmPassword: '',
        });
      });
  };

  render() {
    const { reset } = this.props;
    const { password, confirmPassword } = this.state;
    return (
      <Container
        fluid
        style={{
          background: '#c8d7e0',
          padding: '0px',
          height: '100vh',
        }}
      >
        <div style={{ padding: '40px' }}>
          <h2
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              margin: '0',
              fontFamily: 'Arial',
            }}
          >
            {reset ? 'Reset Password' : 'Account Activation'}
          </h2>
          <Container style={outerContainer}>
            <form>
              <Row
                justify="between"
                style={{
                  ...innerContainer,
                  marginBottom: '24px',
                }}
              >
                <PasswordInfo />
                <Col md={6} sm={12} style={{ padding: '0px' }}>
                  <span style={labelStyle}>Password</span>
                </Col>
                <Col md={6} sm={12} style={{ padding: '0px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '160px',
                    }}
                  >
                    <input
                      style={inputStyle(true)}
                      onBlur={this.onBlur}
                      value={password}
                      onChange={this.onChangePassword}
                      placeholder=""
                      type="password"
                    />
                    <ProgressBar password={password} />
                  </div>
                </Col>
              </Row>
              <Row justify="between" style={innerContainer}>
                <Col md={6} sm={12} style={{ padding: '0px' }}>
                  <span style={labelStyle}>Confirm Password</span>
                </Col>
                <Col md={6} sm={12} style={{ padding: '0px' }}>
                  <div>
                    <input
                      style={inputStyle(true)}
                      onBlur={this.onBlur}
                      value={confirmPassword}
                      onChange={this.onChangeConfirmPassword}
                      placeholder=""
                      type="password"
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '24px',
                }}
              >
                <Button onClick={this.setPassword} type="submit">
                  Set password
                </Button>
              </div>
            </form>
          </Container>
        </div>
        <ToastContainer autoClose={5000} />
      </Container>
    );
  }
}

export default withRouter(Auth);

const outerContainer = {
  margin: '0px',
  marginTop: '20px',
  display: 'flex',
  padding: '40px',
  flexDirection: 'column',
  background: 'white',
  border: '0.5px solid #5381ac',
};

const innerContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px',
};

const inputStyle = isValid => ({
  height: '38px',
  paddingLeft: '10px',
  fontSize: '14px',
  border: `${isValid ? '1px solid #cccccc' : '1px solid red'}`,
  fontFamily: 'Arial',
  flex: '2',
});

const labelStyle = {
  lineHeight: '36px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#5381ac',
  textTransform: 'uppercase',
};
