import React from 'react';

const BusinessUnitsContainer = React.lazy(() => import('./BusinessUnitsContainer'));
const BusinessUnitContainer = React.lazy(() => import('./BusinessUnitContainer'));
const EditBusinessUnitContainer = React.lazy(() => import('./EditBusinessUnitContainer'));
const AddBusinessUnitContainer = React.lazy(() => import('./AddBusinessUnitContainer'));

export {
  BusinessUnitsContainer,
  BusinessUnitContainer,
  EditBusinessUnitContainer,
  AddBusinessUnitContainer,
};
