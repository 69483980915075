import { connect } from "react-redux";
import axios from "axios";
import * as actions from "../actions";
import FaqAdmin from "../components/FaqAdmin";

const mapStateToProps = state => {
  return {
    faqItems: state.faq.faqItems
  };
};

const getFAQs = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/faq`);
  return data;
};

const mapDispatchToProps = dispatch => ({
  getFAQs: () => {
    getFAQs()
      .then(res => {
        dispatch(actions.setFaq(res));
      })
      .catch(err => console.error(err));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqAdmin);
