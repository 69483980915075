import { v4 as uuid } from 'uuid';
import * as actionTypes from './actionTypes';

const defaultSelectValues = {
  name: '',
  startDate: '',
  endDate: '',
  brand: [],
  region: [],
  subregion: [],
  country: [],
  application: [],
  customer: '',
  initiator: null,
  competitors: [],
  projectStatus: [],
  expectedDB1: '',
  expectedQuantity: '',
  fullPotentialQuantity: '',
  rndProject: '',
  approved: false,
  rndProjects: [],
  developmentProjects: [],
  projectLeader: [],
  team: [],
  priority: [],
  brandSupport: [],
  linkedOperativeProjects: [],
  linkedSfProjects: [],
  externalProjectTeam: '',
};
const initialState = {
  projects: [],
  project: {},
  count: 0,
  page: 0,
  selectValues: defaultSelectValues,
  search: '',
  remarks: [],
  initiator: {},
  files: [],
  todoList: [],
  filter: {},
  sort: [],
  todoFiles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CHANGE: {
      if (action.developmentId) {
        const currentSelectValues = { ...state.selectValues };
        currentSelectValues.developmentProjects = currentSelectValues.developmentProjects.map(
          developmentProject => {
            if (developmentProject._id === action.developmentId) {
              const changed = { ...developmentProject };
              changed[action.key] = action.value;
              return changed;
            }
            return developmentProject;
          },
        );
        return { ...state, selectValues: currentSelectValues };
      } else {
        const currentSelectValues = { ...state.selectValues };
        currentSelectValues[action.key] = action.value;
        return { ...state, selectValues: currentSelectValues };
      }
    }
    case actionTypes.ADD_SELECT_DEVELOPMENT_PROJECT: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues.developmentProjects = [
        ...currentSelectValues.developmentProjects,
        {
          _id: uuid(),
        },
      ];
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.REMOVE_SELECT_DEVELOPMENT_PROJECT: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues.developmentProjects = currentSelectValues.developmentProjects.filter(
        dev => dev._id !== action.id,
      );
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.SET_PROJECTS:
      return { ...state, projects: action.projects };
    case actionTypes.SET_PROJECT:
      return { ...state, project: action.project };
    case actionTypes.REMOVE_PROJECT:
      return {
        ...state,
        projects: [...state.projects].filter(el => el.id !== action.id),
      };
    case actionTypes.RESET_FORM:
      return { ...state, selectValues: defaultSelectValues, files: [] };
    case actionTypes.SET_COUNT:
      return { ...state, count: action.count };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.page };
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.search };
    case actionTypes.SET_FILTER:
      return { ...state, filter: action.filter };
    case actionTypes.SET_SORT:
      return { ...state, sort: action.sort };
    case actionTypes.SET_REMARKS:
      return { ...state, remarks: action.remarks };
    case actionTypes.ADD_REMARK:
      return { ...state, remarks: [...state.remarks, action.remark] };
    case actionTypes.REMOVE_REMARK: {
      const filteredRemarks = state.remarks.filter(remark => remark.id !== action.id);
      return { ...state, remarks: filteredRemarks };
    }
    case actionTypes.EDIT_REMARK: {
      const editedRemarks = state.remarks.map(remark =>
        remark.id === action.remark.id ? action.remark : remark,
      );
      return { ...state, remarks: editedRemarks };
    }
    case actionTypes.SET_INITIATOR:
      return { ...state, initiator: action.initiator };
    case actionTypes.SET_FILES:
      return { ...state, files: action.files };
    case actionTypes.REMOVE_FILE: {
      const newFiles = state.files.filter(file => file.name !== action.name);
      return { ...state, files: newFiles };
    }
    case actionTypes.RESET_FILES:
      return { ...state, files: [] };
    case actionTypes.ADD_FILE:
      return { ...state, files: [...state.files, action.file] };
    case actionTypes.ADD_TODO:
      return {
        ...state,
        todoList: [...state.todoList, { ...action.data }],
      };
    case actionTypes.REMOVE_TODO: {
      const newTodo = [...state.todoList].filter(el => el.id !== action.id);
      return { ...state, todoList: newTodo };
    }
    case actionTypes.EDIT_TODO: {
      const editedTodo = [...state.todoList].map(el =>
        el.id === action.id ? { ...action.data } : el,
      );
      return { ...state, todoList: editedTodo };
    }
    case actionTypes.SET_TODOS:
      return { ...state, todoList: action.todos };
    case actionTypes.SET_TODO_FILES:
      return { ...state, todoFiles: action.files };
    case actionTypes.REMOVE_TODO_FILE: {
      const newFiles = state.todoFiles.filter(file => file.name !== action.name);
      return { ...state, todoFiles: newFiles };
    }
    case actionTypes.RESET_TODO_FILES:
      return { ...state, todoFiles: [] };
    case actionTypes.ADD_TODO_FILE:
      return { ...state, todoFiles: [...state.files, action.file] };
    default:
      return state;
  }
};
