export const SELECT_CHANGE = "SELECT_STRATEGIC_PROJECT_CHANGE";
export const RESET_FORM = "RESET_STRATEGIC_PROJECT_FORM";
export const SET_PROJECTS = "SET_STRATEGIC_PROJECTS";
export const SET_PROJECT = "SET_STRATEGIC_PROJECT";
export const REMOVE_PROJECT = "REMOVE_STRATEGIC_PROJECT";
export const SET_COUNT = "SET_STRATEGIC_PROJECT_COUNT";
export const SET_PAGE = "SET_STRATEGIC_PROJECT_PAGE";
export const SET_SEARCH = "SET_STRATEGIC_PROJECT_SEARCH";
export const ADD_MILESTONE = "ADD_STRATEGIC_MILESTONE";
export const CHANGE_BUSINESS_CASE = "CHANGE_STRATEGIC_BUSINESS_CASE";
export const SET_BUSINESS_CASE = "SET_STRATEGIC_BUSINESS_CASE";
export const EDIT_MILESTONE = "EDIT_STRATEGIC_MILESTONE";
export const REMOVE_MILESTONE = "REMOVE_STRATEGIC_MILESTONE";
export const SET_MILESTONES = "SET_STRATEGIC_MILESTONES";
export const CHANGE_TODO = "CHANGE_STRATEGIC_TODO";
export const ADD_TODO = "ADD_STRATEGIC_TODO";
export const EDIT_TODO = "EDIT_STRATEGIC_TODO";
export const REMOVE_TODO = "REMOVE_STRATEGIC_TODO";
export const SET_FILES = "SET_STRATEGIC_FILES";
export const RESET_FILES = "RESET_STRATEGIC_FILE";
export const REMOVE_FILE = "REMOVE_STRATEGIC_FILE";
export const ADD_FILE = "ADD_STRATEGIC_FILE";
export const SET_TODOS = "SET_STRATEGIC_TODOS";
export const ADD_TASK = "ADD_STRATEGIC_TASK";
export const REMOVE_TASK = "REMOVE_STRATEGIC_TASK";
export const SET_TASKS = "SET_STRATEGIC_TASKS";
export const EDIT_TASK = "EDIT_STRATEGIC_TASK";
