import { combineReducers } from 'redux';
//import data from "./pages/data";
import users from './pages/users';
import rights from './pages/rights';
import login from './pages/login';
import dashboard from './pages/dashboard';
import dashboardPmBdm from './pages/dashboard-pm-bdm';
import projects from './pages/projects';
import strategicProjects from './pages/strategic-projects';
import data from './pages/data';
import faq from './pages/faq';
import portfolios from './pages/portfolios';
import businessUnits from './pages/businessUnits';
import strategicProjectsPm from './pages/strategic-projects-pm';

export default combineReducers({
  data: data.reducer,
  users: users.reducer,
  login: login.reducer,
  dashboard: dashboard.reducer,
  dashboardPmBdm: dashboardPmBdm.reducer,
  projects: projects.reducer,
  strategicProjects: strategicProjects.reducer,
  faq: faq.reducer,
  portfolios: portfolios.reducer,
  rights: rights.reducer,
  businessUnits: businessUnits.reducer,
  strategicProjectsPm: strategicProjectsPm.reducer,
});
