import * as actionTypes from "./actionTypes";

export const setFaq = items => ({
  type: actionTypes.SET_FAQ,
  items
});

export const addFaq = item => ({
  type: actionTypes.ADD_FAQ,
  item
});

export const editFaq = item => ({
  type: actionTypes.EDIT_FAQ,
  item
});
