import * as containers from "./containers";
// import * as actions from './actions';
// import * as actionTypes from './actionTypes';
import reducer from "./reducer";

export default {
  containers,
  reducer
  // actions,
  // actionTypes
};
