import React from "react";
import { Col } from "react-grid-system";

const PasswordInfo = () => (
  <Col sm={12} style={{ padding: "0px" }}>
    <div
      style={{
        fontSize: "14px",
        marginBottom: "20px",
        border: "1px solid #5381AC",
        padding: "5px",
        background: "rgb(200, 215, 224)",
        borderRadius: "2px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "#5381AC"
      }}
    >
      <i
        style={{ fontSize: "20px", marginRight: "10px" }}
        className="la la-info"
      />{" "}
      <span>
        Passwords require at least a{" "}
        <span style={{ fontWeight: "bold" }}>length of 8</span> and one of the
        following characters:{" "}
        <span style={{ fontWeight: "bold" }}>Uppercase, Special, Number</span>
      </span>
    </div>
  </Col>
);

export default PasswordInfo;
