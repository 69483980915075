import * as actionTypes from "./actionTypes";

const initialState = {
  faqItems: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FAQ:
      return { ...state, faqItems: [...state.faqItems, action.item] };
    case actionTypes.SET_FAQ:
      return { ...state, faqItems: action.items };
    case actionTypes.EDIT_FAQ: {
      const filteredItems = state.faqItems.map(item =>
        item.id === action.item.id ? action.item : item
      );
      return { ...state, faqItems: filteredItems };
    }
    default:
      return state;
  }
};
