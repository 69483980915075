import React from "react";
import Button from "../../../components/Button";

export default class FaqItem extends React.PureComponent {
  state = {
    editQuestion: "",
    editAnswer: ""
  };

  componentDidMount() {
    const { question, answer } = this.props;
    this.setState({
      editQuestion: question,
      editAnswer: answer
    });
  }

  onClickSave = e => {
    const { editQuestion, editAnswer } = this.state;
    const { onEdit, id, editFAQ } = this.props;
    if (editQuestion !== "" && editAnswer !== "") {
      editFAQ(
        {
          priority: 0,
          question: editQuestion,
          answer: editAnswer
        },
        id
      );
    }
    e.stopPropagation();
    onEdit(-1);
  };

  onCancel = e => {
    const { question, answer, onEdit } = this.props;
    e.stopPropagation();
    this.setState({
      editQuestion: question,
      editAnswer: answer
    });
    onEdit(-1);
  };

  onChangeQuestion = e => {
    this.setState({
      editQuestion: e.target.value
    });
  };

  onChangeAnswer = e => {
    this.setState({
      editAnswer: e.target.value
    });
  };

  render() {
    const { editIndex, index, onEdit, question, answer } = this.props;
    const { editQuestion, editAnswer } = this.state;
    return (
      <div
        onClick={() => onEdit(index)}
        tabIndex={0}
        className="faq-hover"
        role="button"
        style={{
          width: "100%",
          cursor: "pointer",
          borderBottom: "1px solid #C6CBD4",
          padding: "20px 0px",
          transition: "height 1s ease-in-out",
          backgroundColor:
            editIndex === index ? "rgba(200, 215, 224, 0.3)" : "",
          borderLeft: editIndex === index ? "4px solid #5381AC" : ""
        }}
      >
        {editIndex === index ? (
          <input
            placeholder="Your question"
            value={editQuestion}
            onChange={this.onChangeQuestion}
            style={{
              margin: "16px 0px 0px 36px",
              paddingLeft: "5px",
              width: "80%",
              height: "30px",
              fontWeight: "bold",
              fontSize: "14px",
              display: "block"
            }}
          />
        ) : (
          <h2
            style={{
              margin: "16px 0px 0px 36px",
              color: "#5381AC",
              fontWeight: "bold",
              display: "block",
              fontSize: "14px"
            }}
          >
            {question}
          </h2>
        )}

        {editIndex === index ? (
          <textarea
            placeholder="Your answer here..."
            value={editAnswer}
            onChange={this.onChangeAnswer}
            rows="6"
            style={{
              margin: "20px 0px 0px 36px",
              border: "2px solid rgb(238, 238, 238)",
              paddingLeft: "5px",
              width: "80%",
              fontSize: "14px",
              display: "block"
            }}
          />
        ) : (
          <p style={{ margin: "20px 0px 0px 36px" }}>{answer}</p>
        )}
        {editIndex === index ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "20px 0px 0px 36px"
            }}
          >
            <Button onClick={this.onClickSave}>Save FAQ</Button>
            <Button
              onClick={this.onCancel}
              style={{
                background: "lightgrey",
                marginLeft: "20px"
              }}
            >
              Cancel
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
