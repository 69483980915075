import * as actionTypes from './actionTypes';

export const setBusinessUnits = businessUnits => ({
  type: actionTypes.SET_BUSINESS_UNITS,
  businessUnits,
});

export const addBusinessUnit = businessUnit => ({
  type: actionTypes.ADD_BUSINESS_UNIT,
  businessUnit,
});

export const editBusinessUnit = businessUnit => ({
  type: actionTypes.EDIT_BUSINESS_UNIT,
  businessUnit,
});

export const setSearch = search => ({
  type: actionTypes.SET_SEARCH,
  search,
});

export const onSelectChange = (value, key) => ({
  type: actionTypes.SELECT_CHANGE,
  value,
  key,
});

export const resetForm = () => ({
  type: actionTypes.RESET_FORM,
});

export const setBusinessUnit = businessUnit => ({
  type: actionTypes.SET_BUSINESS_UNIT,
  businessUnit,
});

export const setPage = page => ({
  type: actionTypes.SET_PAGE,
  page,
});

export const setCount = count => ({
  type: actionTypes.SET_COUNT,
  count,
});

export const setMilestones = (milestones, projectId) => ({
  type: actionTypes.SET_MILESTONES,
  milestones,
  projectId,
});
