import * as actionTypes from './actionTypes';

const defaultSelect = {
  fullname: '',
  username: '',
  role: '',
  businessUnits: [],
  secondaryBusinessUnits: [],
  applications: [],
  brands: [],
  regions: [],
  subregions: [],
  countries: [],
  access: [],
  teamLeads: [],
  email: '',
  hyperadmin: { label: 'No', value: false },
};
const initialState = {
  selectValues: defaultSelect,
  users: [],
  user: {},
  count: 0,
  page: 0,
  search: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CHANGE: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues[action.key] = action.value;
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.SELECT_CHANGE_INNER: {
      const currentSelectValues = { ...state.selectValues };
      const selectedArray = [...currentSelectValues[action.key]];
      const index = selectedArray.findIndex(val => val.value === action.innerKeyOf);
      if (index !== -1) {
        selectedArray[index][action.innerKey] = action.value;
        currentSelectValues[action.key] = selectedArray;
        return { ...state, selectValues: currentSelectValues };
      } else {
        return state;
      }
    }
    case actionTypes.RESET_FORM:
      return { ...state, selectValues: defaultSelect };
    case actionTypes.SET_USERS:
      return { ...state, users: action.users };
    case actionTypes.SET_USER:
      return { ...state, user: action.user };
    case actionTypes.REMOVE_USER:
      return {
        ...state,
        users: [...state.users].filter(user => user.id !== action.id),
      };
    case actionTypes.SET_COUNT:
      return { ...state, count: action.count };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.page };
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.search };
    default:
      return state;
  }
};
