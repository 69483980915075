import React from 'react';
import customStyles from './selectStyles';
import ReactSelect from 'react-select';

export default function Select(props) {
  const { noBackground } = props;
  let singleValue = {};
  if (noBackground) {
    singleValue = {
      singleValue: base => {
        return { ...customStyles.singleValue(base), backgroundColor: 'transparent' };
      },
    };
  }
  const style = { ...customStyles, ...singleValue, ...props.styles };
  return <ReactSelect {...props} styles={style} />;
}
