import React from "react";
import FaqAdminItem from "../containers/FaqAdminEditContainer";
import FaqAdminAdd from "../containers/FaqAdminAddContainer";

export default class Faq extends React.Component {
  state = {
    editIndex: -1
  };

  componentDidMount() {
    const { getFAQs } = this.props;
    getFAQs();
  }

  onClickEdit = editIndex => {
    this.setState({
      editIndex
    });
  };

  render() {
    const { faqItems } = this.props;
    const { editIndex } = this.state;
    return (
      <div
        style={{ marginTop: "20px", background: "white", padding: "20px 40px" }}
      >
        {faqItems.map((el, index) => (
          <FaqAdminItem
            answer={el.answer}
            question={el.question}
            priority={el.priority}
            id={el.id}
            editIndex={editIndex}
            index={index}
            onEdit={this.onClickEdit}
          />
        ))}
        <FaqAdminAdd />
      </div>
    );
  }
}
