import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Container from './Container';
import users from '../pages/rights';
import data from '../pages/data';
import CustomToast from './CustomToast';
import Header from './Header';
import Faq from '../pages/faq/containers/FaqAdminContainer';
import Button from '../components/Button';
import Tabs from './Tabs';
import businessUnits from '../pages/businessUnits';

const { BusinessUnitsContainer } = businessUnits.containers;
const { DataContainer } = data.containers;
const { UsersContainer } = users.containers;

const AdminHeader = ({ title, renderAdd }) => (
  <Header title={title} style={{ marginBottom: '20px' }}>
    {renderAdd ? (
      <Link to="/addUser?hyper=true" style={{ textDecoration: 'none', color: 'white' }}>
        <Button>Add User</Button>
      </Link>
    ) : null}
  </Header>
);

export default class HyperAdmin extends React.Component {
  state = {
    tabIndex: 0,
  };

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      const { tabIndex, newUser, editUser } = location.state;
      if (tabIndex) {
        this.setState({
          tabIndex,
        });
      } else if (newUser) {
        toast(<CustomToast content="User saved." type="success" />, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.SUCCESS,
        });
      } else if (editUser) {
        toast(<CustomToast content="User updated." type="success" />, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.SUCCESS,
        });
      }
    }
  }

  _onChangeTab = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  render() {
    const { tabIndex } = this.state;
    const { location } = this.props;
    return (
      <Container>
        <AdminHeader title="HyperAdmin" renderAdd={tabIndex === 1} />
        <Tabs
          tabIndex={tabIndex}
          onChange={this._onChangeTab}
          headings={[
            { label: 'Business Units', icon: 'la-database' },
            { label: 'All Users', icon: 'la-users' },
          ]}
        />
        {tabIndex === 0 ? <BusinessUnitsContainer location={location} /> : <UsersContainer />}
        <ToastContainer autoClose={5000} />
      </Container>
    );
  }
}
