const customStyles = {
  option: base => ({
    ...base,
    padding: 10,
  }),
  container: base => ({
    ...base,
    flex: 2,
    lineHeight: '16px',
    background: 'white',
    wordBreak: 'break-all',
  }),
  control: () => ({
    // none of react-selects styles are passed to <View />
    display: 'flex',
    border: '1px solid #cccccc',
  }),
  singleValue: base => ({
    ...base,
    backgroundColor: '#c8d7e0',
    padding: '3px',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Arial',
  }),
  multiValueLabel: base => ({
    ...base,
    backgroundColor: '#c8d7e0',
    color: 'black',
    fontSize: '14px',
    fontFamily: 'Arial',
  }),
  clearIndicator: base => ({
    ...base,
    color: '#5381ac',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#5381ac',
  }),
  multiValueRemove: base => ({
    ...base,
    backgroundColor: '#c8d7e0',
    borderLeft: '1px solid white',
    color: '#5381ac',
  }),

  multiValue: base => ({
    ...base,
    borderRadius: '0px',
  }),
};

export default customStyles;
