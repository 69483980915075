import * as actionTypes from './actionTypes';

export const addData = (dataType, dataValue) => {
  return { type: actionTypes.ADD_DATA, dataType, dataValue };
};

export const patchData = (dataId, dataValue) => {
  return { type: actionTypes.PATCH_DATA, dataId, dataValue };
};

export const setData = (dataType, data) => {
  return { type: actionTypes.SET_DATA, dataType, data };
};

export const setType = dataType => {
  return { type: actionTypes.SET_TYPE, dataType };
};

export const removeData = dataId => {
  return { type: actionTypes.REMOVE_DATA, dataId };
};

export const setRegions = regions => {
  return { type: actionTypes.SET_REGIONS, regions };
};

export const setSubregions = subregions => {
  return { type: actionTypes.SET_SUBREGIONS, subregions };
};

export const setCountries = countries => {
  return { type: actionTypes.SET_COUNTRIES, countries };
};

export const setTeamLeads = teamLeads => {
  return { type: actionTypes.SET_TEAMLEADS, teamLeads };
};

export const setAllBrands = brands => {
  return { type: actionTypes.SET_ALL_BRANDS, brands };
};

export const setBrands = brands => {
  return { type: actionTypes.SET_BRANDS, brands };
};

export const setFields = fields => {
  return { type: actionTypes.SET_FIELDS, fields };
};

export const setUsers = users => {
  return { type: actionTypes.SET_USERS, users };
};

export const setStrategicProjects = projects => {
  return { type: actionTypes.SET_STRATEGIC_PROJECTS, projects };
};

export const setApplications = applications => {
  return { type: actionTypes.SET_APPLICATIONS, applications };
};

export const setProjectStatuses = projectStatuses => {
  return { type: actionTypes.SET_PROJECT_STATUSES, projectStatuses };
};
export const setStrategicProjectStatuses = strategicProjectStatuses => {
  return { type: actionTypes.SET_STRATEGIC_PROJECT_STATUSES, strategicProjectStatuses };
};
export const setPriorities = priorities => {
  return { type: actionTypes.SET_PRIORITIES, priorities };
};

export const setEntities = entities => {
  return { type: actionTypes.SET_ENTITIES, entities };
};

export const setClassifications = classifications => {
  return { type: actionTypes.SET_CLASSIFICATIONS, classifications };
};

export const setCustomerSegmentations = customerSegmentations => {
  return { type: actionTypes.SET_CUSTOMER_SEGMENTATIONS, customerSegmentations };
};

export const setStrategicProjectTypes = strategicProjectTypes => {
  return { type: actionTypes.SET_STRATEGIC_PROJECT_TYPES, strategicProjectTypes };
};

export const setStatusResons = statusReasons => {
  return { type: actionTypes.SET_STATUS_REASONS, statusReasons };
};

export const setCompetitors = competitors => {
  return { type: actionTypes.SET_COMPETITORS, competitors };
};

export const setBusinessUnits = businessUnits => {
  return { type: actionTypes.SET_BUSINESS_UNITS, businessUnits };
};

export const setPredefinedTasks = tasks => {
  return { type: actionTypes.SET_PREDEFINED_TASKS, tasks };
};

export const setProjects = projects => {
  return { type: actionTypes.SET_PROJECTS, projects };
};

export const onSelectChange = (value, key) => ({
  type: actionTypes.SELECT_CHANGE,
  value,
  key,
});

export const onEditChange = (value, key) => ({
  type: actionTypes.EDIT_CHANGE,
  value,
  key,
});
