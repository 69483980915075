import * as actionTypes from "./actionTypes";

const defaultSelectValues = {
  name: "",
  startDate: "",
  endDate: "",
  notes: "",
  targetDescription: "",
  team: [],
  lead: []
};

const defaultBusinessCase = {
  expectedDB1: {
    firstYear: 0,
    thirdYear: 0,
    seventhYear: 0
  },
  investmentCosts: {
    firstYear: 0,
    thirdYear: 0,
    seventhYear: 0
  },
  additionalStaff: {
    firstYear: 0,
    thirdYear: 0,
    seventhYear: 0
  },
  percentPerCapita: {
    firstYear: 0,
    thirdYear: 0,
    seventhYear: 0
  }
};
const initialState = {
  projects: [],
  project: {},
  count: 0,
  milestones: [],
  tasks: [],
  files: [],
  todoList: [],
  businessCase: defaultBusinessCase,
  page: 0,
  selectValues: defaultSelectValues,
  search: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CHANGE: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues[action.key] = action.value;
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.SET_PROJECTS:
      return { ...state, projects: action.projects };
    case actionTypes.SET_PROJECT:
      return { ...state, project: action.project };
    case actionTypes.REMOVE_PROJECT:
      return {
        ...state,
        projects: [...state.projects].filter(
          project => project.id !== action.id
        )
      };
    case actionTypes.RESET_FORM:
      return {
        ...state,
        selectValues: defaultSelectValues,
        files: [],
        milestones: [],
        todoList: [],
        businessCase: defaultBusinessCase
      };
    case actionTypes.SET_COUNT:
      return { ...state, count: action.count };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.page };
    case actionTypes.SET_SEARCH:
      return { ...state, search: action.search };
    case actionTypes.SET_MILESTONES:
      return { ...state, milestones: action.milestones };
    case actionTypes.ADD_MILESTONE:
      return { ...state, milestones: [...state.milestones, action.milestone] };
    case actionTypes.EDIT_MILESTONE: {
      const editedMilestones = [...state.milestones].map(el =>
        el.id === action.id ? action.milestone : el
      );
      return { ...state, milestones: editedMilestones };
    }
    case actionTypes.REMOVE_MILESTONE: {
      const newMilestones = [...state.milestones].filter(
        el => el.id !== action.id
      );
      return { ...state, milestones: newMilestones };
    }
    case actionTypes.SET_BUSINESS_CASE:
      return { ...state, businessCase: action.data };
    case actionTypes.CHANGE_BUSINESS_CASE: {
      const nextCase = { ...state.businessCase };
      nextCase[action.key][action.year] = action.value;
      return { ...state, businessCase: nextCase };
    }

    case actionTypes.ADD_TODO:
      return {
        ...state,
        todoList: [...state.todoList, { data: action.data, id: action.id }]
      };
    case actionTypes.REMOVE_TODO: {
      const newTodo = [...state.todoList].filter(el => el.id !== action.id);
      return { ...state, todoList: newTodo };
    }
    case actionTypes.EDIT_TODO: {
      const editedTodo = [...state.todoList].map(el =>
        el.id === action.id ? { data: action.data, id: action.id } : el
      );
      return { ...state, todoList: editedTodo };
    }
    case actionTypes.SET_TODOS:
      return { ...state, todoList: action.todos };

    case actionTypes.SET_FILES:
      return { ...state, files: action.files };
    case actionTypes.REMOVE_FILE: {
      const newFiles = state.files.filter(file => file.name !== action.name);
      return { ...state, files: newFiles };
    }
    case actionTypes.RESET_FILES:
      return { ...state, files: [] };
    case actionTypes.ADD_FILE:
      return { ...state, files: [...state.files, action.file] };

    case actionTypes.SET_TASKS:
      return { ...state, tasks: action.tasks };

    case actionTypes.ADD_TASK: {
      const taskedMilestones = [...state.milestones].map(el =>
        el.id === action.milestoneId
          ? { ...el, tasks: [...el.tasks, action.task] }
          : el
      );
      return { ...state, milestones: taskedMilestones };
    }
    case actionTypes.REMOVE_TASK: {
      const finalMilestones = [...state.milestones].filter(milestone => {
        const newMilestone = milestone;
        if (milestone.id === action.milestoneId) {
          const filteredTasks = milestone.tasks.filter(
            el => el.id !== action.id
          );
          newMilestone.tasks = filteredTasks;
        }
        return newMilestone;
      });
      return { ...state, milestones: finalMilestones };
    }
    case actionTypes.EDIT_TASK: {
      const editedMilestoneTasks = [...state.milestones].filter(milestone => {
        const newMilestone = milestone;
        if (milestone.id === action.milestoneId) {
          const mappedTasks = milestone.tasks.map(el =>
            el.id === action.id ? action.task : el
          );
          newMilestone.tasks = mappedTasks;
        }
        return newMilestone;
      });
      return { ...state, milestones: editedMilestoneTasks };
    }
    default:
      return state;
  }
};
