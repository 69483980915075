import React from "react";

const StrategicProjectsContainer = React.lazy(() =>
  import("./StrategicProjectsContainer")
);
const AddStrategicProjectContainer = React.lazy(() =>
  import("./AddStrategicProjectContainer")
);
const EditStrategicProjectContainer = React.lazy(() =>
  import("./EditStrategicProjectContainer")
);

export {
  StrategicProjectsContainer,
  AddStrategicProjectContainer,
  EditStrategicProjectContainer
};
