/* eslint-disable no-param-reassign */
import React from "react";

const Filler = ({ percentage }) => (
  <div style={{ ...fillerStyle(percentage) }} className="filler" />
);

const ProgressBar = ({ percentage }) => {
  return (
    <div style={barStyle} className="progress-bar">
      <Filler percentage={percentage} />
    </div>
  );
};

const barStyle = {
  position: "relative",
  marginTop: "10px",
  height: "10px",
  width: "158px",
  borderRadius: "2px",
  border: "1px solid lightgrey"
};

function hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hslToRgb(h, s, l) {
  let r;
  let g;
  let b;

  if (s === 0) {
    // eslint-disable-next-line no-multi-assign
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}

function numberToColorHsl(i, min, max) {
  let ratio = i;
  if (min > 0 || max < 1) {
    if (i < min) {
      ratio = 0;
    } else if (i > max) {
      ratio = 1;
    } else {
      const range = max - min;
      ratio = (i - min) / range;
    }
  }

  const hue = (ratio * 1.2) / 3.6;
  const rgb = hslToRgb(hue, 1, 0.5);
  return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
}

const fillerStyle = percentage => ({
  background: numberToColorHsl(percentage / 100, 0, 1),
  width: `${percentage}%`,
  height: "100%",
  borderRadius: "inherit",
  transition: "all 0.5s ease-in"
});

export default class ProgressBarContainer extends React.Component {
  calculatePercentage = () => {
    const { password } = this.props;
    let score = 0;
    if (password.length >= 8) {
      score += 1;
    }
    if (/[A-Z]/.test(password)) {
      score += 1;
    }
    if (/\d/.test(password)) {
      score += 1;
    }

    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if (format.test(password)) {
      score += 1;
    }
    return score * 25;
  };

  render() {
    return (
      <div>
        <ProgressBar percentage={this.calculatePercentage()} />
      </div>
    );
  }
}
