import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Container from "./Container";
import users from "../pages/users";
import data from "../pages/data";
import CustomToast from "./CustomToast";
import Header from "./Header";
import Faq from "../pages/faq/containers/FaqAdminContainer";
import Button from "../components/Button";
import Tabs from "./Tabs";

const { DataContainer } = data.containers;
const { UsersContainer } = users.containers;

const AdminHeader = ({ title, renderAdd }) => (
  <Header title={title} style={{ marginBottom: "20px" }}>
    {renderAdd ? (
      <Link to="/addUser" style={{ textDecoration: "none", color: "white" }}>
        <Button>Add User</Button>
      </Link>
    ) : null}
  </Header>
);

export default class Admin extends React.Component {
  state = {
    tabIndex: 0
  };

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      const { tabIndex, newUser, editUser } = location.state;
      if (tabIndex) {
        this.setState({
          tabIndex
        });
      } else if (newUser) {
        toast(<CustomToast content="User saved." type="success" />, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.SUCCESS
        });
      } else if (editUser) {
        toast(<CustomToast content="User updated." type="success" />, {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.SUCCESS
        });
      }
    }
  }

  _onChangeTab = tabIndex => {
    this.setState({
      tabIndex
    });
  };

  render() {
    const { tabIndex } = this.state;
    return (
      <Container>
        <AdminHeader title="Admin" renderAdd={tabIndex === 1} />
        <Tabs
          tabIndex={tabIndex}
          onChange={this._onChangeTab}
          headings={[
            { label: "Data", icon: "la-database" },
            { label: "Users", icon: "la-users" },
            { label: "FAQ", icon: "la-info-circle" }
          ]}
        />
        {tabIndex === 0 ? (
          <DataContainer />
        ) : tabIndex === 1 ? (
          <UsersContainer />
        ) : (
          <Faq />
        )}
        <ToastContainer autoClose={5000} />
      </Container>
    );
  }
}
