import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routing from './Routing';

const mapStateToProps = state => {
  return {
    loggedIn: state.login.loggedIn,
    strategicAccess: state.login.strategicAccess,
    operativeAccess: state.login.operativeAccess,
    isAdmin:
      state.login.role.toLowerCase() === 'admin' || state.login.role.toLowerCase() === 'hyperadmin',
    isHyperAdmin: state.login.isHyperAdmin,
  };
};

export default withRouter(connect(mapStateToProps)(Routing));
