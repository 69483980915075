import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import stc from '../../../images/colored/stc.png';
import hy from '../../../images/colored/hy.png';
import mc from '../../../images/colored/mc.png';
import db from '../../../images/colored/db.png';
import oa from '../../../images/colored/oa.png';
import se from '../../../images/colored/se.png';
import sv from '../../../images/colored/sv.png';
import hsa from '../../../images/colored/hsa.png';
import CustomToast from '../../../utils/CustomToast';
import Button from '../../../components/Button';
import classes from './ResetPassword.module.scss';

class ResetPassword extends React.Component {
  state = {
    email: '',
  };

  onChange = e => {
    this.setState({
      email: e.target.value,
    });
  };

  resetPassword = e => {
    e.preventDefault();
    const { email } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_ORIGIN}/accounts/reset-password`, {
        email,
      })
      .then(() => {
        this.setState({
          email: '',
        });
        toast(
          <CustomToast
            content="Password successfully reset. Check your email for further steps."
            type="success"
          />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.SUCCESS,
          },
        );
      })
      // TODO
      .catch(res => {
        toast(
          <CustomToast content={res.data ? res.data.message : 'Invalid email.'} type="error" />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.ERROR,
          },
        );
        this.setState({
          email: '',
        });
      });
  };

  render() {
    const { email } = this.state;
    return (
      <Container fluid className={classes.container}>
        <div style={{ padding: '40px', height: '50%', alignSelf: 'center' }}>
          <h2 className={classes.heading}>Reset Password</h2>
          <Container style={outerContainer}>
            <form>
              <Row justify="between" style={innerContainer}>
                <Col md={12} sm={12} style={{ padding: '0px' }}>
                  <span style={labelStyle}>Email</span>
                </Col>
                <Col md={12} sm={12} style={{ padding: '0px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={inputStyle(true)}
                      onBlur={this.onBlur}
                      value={email}
                      onChange={this.onChange}
                      placeholder=""
                      type="text"
                    />
                  </div>
                </Col>
              </Row>

              <div className={classes.buttonContainer}>
                <Button onClick={this.resetPassword} type="submit">
                  Reset password
                </Button>
              </div>
            </form>
          </Container>
        </div>
        <Row justify="center" className={classes.imgRow}>
          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Mühlenchemie" src={mc} className={classes.img} />
          </Col>
          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Hydrosol" src={hy} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Sternvitamin" src={sv} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="HS Additive" src={hsa} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="SternEnzym" src={se} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Olbricht Arom" src={oa} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Deutsche Back" src={db} className={classes.img} />
          </Col>

          <Col lg={3} md={6} xs={6} sm={6} className={classes.imgCol}>
            <img alt="Stern Technology Center" src={stc} className={classes.img} />
          </Col>
        </Row>
        <ToastContainer autoClose={5000} />
      </Container>
    );
  }
}

export default ResetPassword;

const outerContainer = {
  margin: '0px',
  marginTop: '20px',
  display: 'flex',
  padding: '40px',
  flexDirection: 'column',
  background: 'white',
  border: '0.5px solid #5381ac',
};

const innerContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px',
};

const inputStyle = isValid => ({
  height: '38px',
  paddingLeft: '10px',
  fontSize: '14px',
  border: `${isValid ? '1px solid #cccccc' : '1px solid red'}`,
  fontFamily: 'Arial',
  flex: '2',
});

const labelStyle = {
  lineHeight: '36px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#5381ac',
  textTransform: 'uppercase',
};
