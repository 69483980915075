import { connect } from 'react-redux';
import axios from 'axios';
import SideMenu from './SideMenu';
import { setLogin } from '../../pages/login/actions';
import { onSelectChange } from '../../pages/businessUnits/actions';

const mapStateToProps = state => {
  return {
    loggedIn: state.login.loggedIn,
    strategicAccess: state.login.strategicAccess,
    operativeAccess: state.login.operativeAccess,
    isAdmin:
      state.login.role.toLowerCase() === 'admin' || state.login.role.toLowerCase() === 'hyperadmin',
    isHyperAdmin: state.login.isHyperAdmin,
    businessUnits: state.businessUnits.selectValues.businessUnits,
    currentBusinessUnit: state.login.businessUnit,
  };
};

const logout = async () => {
  return await axios.post(`${process.env.REACT_APP_API_ORIGIN}/auth/logout`);
};

const switchBusinessUnit = async id => {
  await axios.post(`${process.env.REACT_APP_API_ORIGIN}/business-unit/switch`, {
    businessUnitId: id,
  });
  window.location.pathname = '/dashboard';
};

const setBusinessUnits = async dispatch => {
  return await axios.get(`${process.env.REACT_APP_API_ORIGIN}/business-unit/mines`);
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      logout()
        .then(() => {
          localStorage.removeItem('token');
          dispatch(setLogin(false));
        })
        .catch(err => console.error(err));
    },
    setBusinessUnits: () => {
      setBusinessUnits()
        .then(({ data }) => {
          const values = data.map(el => {
            return { label: el.name, value: el.id };
          });
          dispatch(onSelectChange(values, 'businessUnits'));
        })
        .catch(err => console.error(err));
    },
    switchBusinessUnit: id => switchBusinessUnit(id),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideMenu);
