import React from "react";
import { Container, Row, Col } from "react-grid-system";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CustomToast from "../../../utils/CustomToast";
import ParentContainer from "../../../utils/Container";
import Button from "../../../components/Button";
import ProgressBar from "./ProgressBar";
import PasswordInfo from "./PasswordInfo";

class ResetPassword extends React.Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };

  onChange = e => {
    this.setState({
      oldPassword: e.target.value
    });
  };

  onChangePassword = e => {
    this.setState({
      newPassword: e.target.value
    });
  };

  onChangeConfirmPassword = e => {
    this.setState({
      confirmNewPassword: e.target.value
    });
  };

  testPassword = password => {
    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    return regex.test(password);
  };

  changePassword = e => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    e.preventDefault();
    this.testPassword(newPassword);
    if (newPassword !== confirmNewPassword) {
      toast(<CustomToast content="Passwords do not match." type="error" />, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: toast.TYPE.ERROR
      });
      return;
    }
    if (!this.testPassword(newPassword)) {
      toast(
        <CustomToast
          content="Password must be at least 8 characters long and contain at least one special, numeric and uppercase character."
          type="error"
        />,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          type: toast.TYPE.ERROR
        }
      );

      return;
    }
    axios
      .put(`${process.env.REACT_APP_API_ORIGIN}/auth/password`, {
        oldPassword,
        newPassword
      })
      .then(() => {
        this.setState({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        });
        toast(
          <CustomToast
            content="Password successfully changed."
            type="success"
          />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.SUCCESS
          }
        );
      })
      .catch(({ response }) => {
        toast(
          <CustomToast
            content={
              response && response.data
                ? response.data.message
                : "Error changing password."
            }
            type="error"
          />,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            type: toast.TYPE.ERROR
          }
        );
        this.setState({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        });
      });
  };

  render() {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    return (
      <ParentContainer>
        <h2
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            margin: "0",
            fontFamily: "Arial"
          }}
        >
          Settings
        </h2>
        <Container style={outerContainer}>
          <form>
            <Row justify="between" style={innerContainer}>
              <PasswordInfo />
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <span style={labelStyle}>Old Password</span>
              </Col>
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <div>
                  <input
                    style={inputStyle(true)}
                    onBlur={this.onBlur}
                    value={oldPassword}
                    onChange={this.onChange}
                    placeholder=""
                    type="password"
                  />
                </div>
              </Col>
            </Row>
            <Row
              justify="between"
              style={{ ...innerContainer, marginTop: "24px" }}
            >
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <span style={labelStyle}>New Password</span>
              </Col>
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "160px"
                  }}
                >
                  <input
                    style={inputStyle(true)}
                    onBlur={this.onBlur}
                    value={newPassword}
                    onChange={this.onChangePassword}
                    placeholder=""
                    type="password"
                  />
                  <ProgressBar password={newPassword} />
                </div>
              </Col>
            </Row>
            <Row
              justify="between"
              style={{ ...innerContainer, marginTop: "24px" }}
            >
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <span style={labelStyle}>Confirm New Password</span>
              </Col>
              <Col md={6} sm={12} style={{ padding: "0px" }}>
                <div>
                  <input
                    style={inputStyle(true)}
                    onBlur={this.onBlur}
                    value={confirmNewPassword}
                    onChange={this.onChangeConfirmPassword}
                    placeholder=""
                    type="password"
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px"
              }}
            >
              <Button onClick={this.changePassword} type="submit">
                Change password
              </Button>
            </div>
          </form>
        </Container>

        <ToastContainer autoClose={5000} />
      </ParentContainer>
    );
  }
}

export default ResetPassword;

const outerContainer = {
  margin: "0px",
  marginTop: "20px",
  display: "flex",
  padding: "40px",
  flexDirection: "column",
  background: "white",
  border: "0.5px solid #5381ac"
};

const innerContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  margin: "0px"
};

const inputStyle = isValid => ({
  height: "38px",
  paddingLeft: "10px",
  fontSize: "14px",
  border: `${isValid ? "1px solid #cccccc" : "1px solid red"}`,
  fontFamily: "Arial",
  flex: "2"
});

const labelStyle = {
  lineHeight: "36px",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#5381ac",
  textTransform: "uppercase"
};
