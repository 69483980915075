import * as actionTypes from './actionTypes';

const initialState = {
  token: '',
  // has to be null because of redirects
  loggedIn: null,
  strategicAccess: false,
  operativeAccess: false,
  role: '',
  id: '',
  businessUnit: null,
  leadedUsersCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return { ...state, token: action.token };
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        leadedUsersCount: action.loginData ? action.loginData.leadedUsersCount : 0,
        loggedIn: action.loginData ? action.loginData.activated : false,
        strategicAccess: action.loginData
          ? action.loginData.canAccessStrategicProjects || action.loginData.role === 'Admin'
          : false,
        operativeAccess: action.loginData
          ? action.loginData.canAccessOperativeProjects || action.loginData.role === 'Admin'
          : false,
        role: action.loginData ? action.loginData.role : '',
        id: action.loginData ? action.loginData.id : '',
        fullName: action.loginData ? action.loginData.fullName : '',
        businessUnit: action.loginData ? action.loginData.currentBusinessUnit.businessUnit : null,
        isHyperAdmin: action.loginData ? action.loginData.isHyperAdmin : false,
      };
    default:
      return state;
  }
};
