import * as containers from './containers';
import reducer from './reducer';

const routes = {
  base: '/pm-strategic-projects',
  filter: '/pm-strategic-projects/filter/:filter',
  single: '/pm-strategic-projects/:id',
  add: '/pm-strategic-projects/add-project',
  edit: id => `/pm-strategic-projects/edit-project/${id || ':id'}`,
};

export default {
  routes,
  containers,
  reducer,
};
