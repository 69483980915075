import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import packageJson from '../../../../package.json';
import logo from '../../../images/colored/logo-sf.png';
import classes from './Login.module.scss';
const Logos = lazy(() => import('./Logos'));
const EnhancedButton = ({ login, username, password, keepLogin }) => {
  return (
    <button
      className={classes.button}
      type="submit"
      onClick={e => {
        e.preventDefault();
        login(username, password, keepLogin);
      }}
    >
      Login
    </button>
  );
};

export default class Login extends React.PureComponent {
  state = {
    username: '',
    password: '',
    keepLogin: true,
  };

  onChangeUsername = e => {
    this.setState({
      username: e.target.value,
    });
  };

  onChangePassword = e => {
    this.setState({
      password: e.target.value,
    });
  };

  _onChangeCheckbox = () => {
    this.setState(prevState => ({
      keepLogin: !prevState.keepLogin,
    }));
  };

  render() {
    const { login } = this.props;
    const { username, password, keepLogin } = this.state;
    return (
      <div className={classes.container}>
        <img alt="Logo" className={classes.logo} src={logo} />
        <p className={classes.version}>Version: {packageJson.version}</p>
        <h3 className={classes.title}>Sign into your account</h3>
        <form className={classes.form}>
          <input
            value={username}
            onChange={this.onChangeUsername}
            className={classes.input}
            name="username"
            placeholder="Username"
          />
          <input
            value={password}
            onChange={this.onChangePassword}
            className={classes.input}
            name="password"
            type="password"
            placeholder="Password"
          />
          <div className={classes.preferenceBar}>
            <Link to="/resetPassword" className={classes.resetLink}>
              <span>Forgot password?</span>
            </Link>
            <span>
              <input type="checkbox" checked={keepLogin} onChange={this._onChangeCheckbox} />
              Keep me logged in
            </span>
          </div>
          <EnhancedButton
            login={login}
            username={username}
            password={password}
            keepLogin={keepLogin}
          />
        </form>
        <Suspense fallback={<div>Loading...</div>}>
          <Logos />
        </Suspense>
        <ToastContainer autoClose={5000} />
      </div>
    );
  }
}
