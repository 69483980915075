import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import auth from '../../pages/auth';
import businessUnits from '../../pages/businessUnits';
import dashboard from '../../pages/dashboard';
import dashboardPmBdm from '../../pages/dashboard-pm-bdm';
import faq from '../../pages/faq';
import login from '../../pages/login';
import portfolios from '../../pages/portfolios';
import projects from '../../pages/projects';
import rights from '../../pages/rights';
import strategicProjects from '../../pages/strategic-projects';
import strategicProjectsPM from '../../pages/strategic-projects-pm';
import users from '../../pages/users';
import Admin from '../Admin';
import HyperAdmin from '../HyperAdmin';

const {
  AddBusinessUnitContainer,
  EditBusinessUnitContainer,
  BusinessUnitContainer,
} = businessUnits.containers;

const {
  PortfoliosContainer,
  PortfolioContainer,
  AddPortfolioContainer,
  EditPortfolioContainer,
} = portfolios.containers;
const { AddUserContainer, EditUserContainer } = users.containers;
const { EditRightsContainer } = rights.containers;
const { AuthContainer, ResetPasswordContainer, SettingsContainer } = auth.containers;
const { FAQContainer } = faq.containers;
const { ProjectsContainer, AddProjectContainer, EditProjectContainer } = projects.containers;
const {
  StrategicProjectsContainer,
  AddStrategicProjectContainer,
  EditStrategicProjectContainer,
} = strategicProjects.containers;
const { LoginContainer } = login.containers;
const { DashboardContainer } = dashboard.containers;
const { DashboardContainer: DashboardPmBdmContainer } = dashboardPmBdm.containers;

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return loggedIn ? (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect
          push
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const StrategicRoute = ({ component: Component, strategicAccess, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      strategicAccess ? (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect
          push
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const OperativeRoute = ({ component: Component, operativeAccess, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      operativeAccess ? (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect
          push
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const BaseRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return loggedIn ? (
        <Redirect push to={{ pathname: '/dashboard', state: { from: props.location } }} />
      ) : (
        <Redirect
          push
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const ProtectedRoute = ({ component: Component, isAdmin, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdmin ? (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect
          push
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const EnhancedLogin = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return loggedIn ? (
        <Redirect
          push
          to={{
            pathname: '/dashboard',
            state: { from: props.location },
          }}
        />
      ) : (
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      );
    }}
  />
);

const Routing = ({ loggedIn, isAdmin, isHyperAdmin, strategicAccess, operativeAccess }) => {
  return (
    <Switch>
      <BaseRoute loggedIn={loggedIn} exact path="/" component={DashboardContainer} />
      <EnhancedLogin loggedIn={loggedIn} path="/login" component={LoginContainer} />
      <PrivateRoute
        loggedIn={loggedIn}
        exact
        path="/pm-bdm-dashboard"
        component={DashboardPmBdmContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path="/operative-projects/filter/:filter"
        component={ProjectsContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path="/operative-projects/:id"
        component={ProjectsContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path="/operative-projects"
        component={ProjectsContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path={strategicProjectsPM.routes.edit()}
        component={strategicProjectsPM.containers.EditProjectContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path={strategicProjectsPM.routes.add}
        component={strategicProjectsPM.containers.AddProjectContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path={strategicProjectsPM.routes.filter}
        component={strategicProjectsPM.containers.ProjectsContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path={strategicProjectsPM.routes.single}
        component={strategicProjectsPM.containers.ProjectsContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path={strategicProjectsPM.routes.base}
        component={strategicProjectsPM.containers.ProjectsContainer}
      />
      <StrategicRoute
        key={1}
        strategicAccess={strategicAccess}
        path="/strategic-projects/:id"
        component={StrategicProjectsContainer}
      />
      <StrategicRoute
        key={1}
        strategicAccess={strategicAccess}
        path="/strategic-projects"
        component={StrategicProjectsContainer}
      />
      <StrategicRoute
        key={2}
        strategicAccess={strategicAccess}
        path="/addStrategicProject/:id"
        component={AddStrategicProjectContainer}
      />
      <StrategicRoute
        key={3}
        strategicAccess={strategicAccess}
        path="/portfolios/:id"
        component={PortfolioContainer}
      />
      <StrategicRoute
        key={4}
        strategicAccess={strategicAccess}
        path="/portfolios"
        component={PortfoliosContainer}
      />
      <StrategicRoute
        key={5}
        strategicAccess={strategicAccess}
        path="/addPortfolio"
        component={AddPortfolioContainer}
      />
      <StrategicRoute
        key={6}
        strategicAccess={strategicAccess}
        path="/editPortfolio/:id"
        component={EditPortfolioContainer}
      />
      <StrategicRoute
        key={7}
        strategicAccess={strategicAccess}
        path="/editStrategicProject/:id"
        component={EditStrategicProjectContainer}
      />

      <PrivateRoute loggedIn={loggedIn} path="/dashboard" component={DashboardContainer} />
      <StrategicRoute
        key={7}
        strategicAccess={strategicAccess}
        path="/businessUnits/:id"
        component={BusinessUnitContainer}
      />
      <ProtectedRoute isAdmin={isAdmin} path="/addUser" component={AddUserContainer} />
      <ProtectedRoute isAdmin={isAdmin} path="/editUser/:id" component={EditUserContainer} />
      <ProtectedRoute isAdmin={isAdmin} path="/editRights/:id" component={EditRightsContainer} />
      <ProtectedRoute
        isAdmin={isAdmin}
        path="/addBusinessUnit"
        component={AddBusinessUnitContainer}
      />
      <ProtectedRoute
        isAdmin={isAdmin}
        path="/editBusinessUnit/:id"
        component={EditBusinessUnitContainer}
      />
      <PrivateRoute loggedIn={loggedIn} path="/faq" component={FAQContainer} />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path="/addProject"
        component={AddProjectContainer}
      />
      <OperativeRoute
        operativeAccess={operativeAccess}
        path="/editProject/:id"
        component={EditProjectContainer}
      />
      <Route path="/auth/reset/:token" render={() => <AuthContainer reset />} />
      <Route path="/auth/activate/:token" render={() => <AuthContainer />} />
      <Route path="/resetPassword" component={ResetPasswordContainer} />
      <PrivateRoute path="/api" render={() => <div>Downloading file...</div>} />
      <PrivateRoute loggedIn={loggedIn} path="/settings" component={SettingsContainer} />
      <ProtectedRoute isAdmin={isAdmin} path="/admin" component={Admin} />
      <ProtectedRoute isAdmin={isHyperAdmin} path="/hyperadmin" component={HyperAdmin} />
      <ProtectedRoute
        isAdmin={isHyperAdmin}
        path="/hyperadmin/addUser"
        component={AddUserContainer}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routing;
