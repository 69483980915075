import React from 'react';

export default class Tabs extends React.PureComponent {
  render() {
    const { headings, tabIndex, onChange } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: '57px',
          display: 'flex',
          marginBottom: '20px',
          textTransform: 'uppercase',
          overflow: 'hidden',
        }}
      >
        {headings.map((heading, index) => (
          <div
            role="button"
            tabIndex={0}
            key={index}
            style={{
              ...tabStyle(tabIndex, index),
              borderRight: '0.5px solid #5381AC',
            }}
            onClick={() =>
              onChange(heading && heading.tabIndex !== undefined ? heading.tabIndex : index)
            }
          >
            <i
              className={`la ${heading.icon}`}
              style={{
                marginRight: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            />
            <span>{heading.label}</span>
          </div>
        ))}
      </div>
    );
  }
}
const tabStyle = (tabIndex, index) => ({
  flex: 1,
  textAlign: 'center',
  lineHeight: '57px',
  cursor: 'pointer',
  backgroundColor: 'white',
  fontWeight: 'bold',
  color: tabIndex === index ? '#5381AC' : 'black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
