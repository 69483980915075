import * as actionTypes from './actionTypes';

const initialState = {
  dataType: null,
  data: [],
  fields: [],
  regions: [],
  subregions: [],
  countries: [],
  allBrands: [],
  brands: [],
  users: [],
  strategicProjects: [],
  applications: [],
  competitors: [],
  priorities: [],
  classifications: [],
  projectStatuses: [],
  strategicProjectStatuses: [],
  businessUnits: [],
  teamLeads: [],
  predefinedTasks: [],
  entities: [],
  customerSegmentations: [],
  strategicProjectTypes: [],
  statusReasons: [],
  projects: [],
  strategicProjectsPm: [],
  selectValues: {
    inRegions: [],
    inSubregions: [],
    hasSubregions: [],
    hasCountries: [],
    hasFields: [],
    hasStatuses: [],
  },
  editValues: {
    inRegions: [],
    inSubregions: [],
    hasSubregions: [],
    hasCountries: [],
    inBrands: [],
    funnelWeight: 0,
    hasFields: [],
    enablePdm: false,
    hasStatuses: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TYPE:
      return { ...state, dataType: action.dataType };
    case actionTypes.SET_DATA: {
      const test = { ...state, data: action.data };
      return test;
    }
    case actionTypes.REMOVE_DATA: {
      const removedData = state.data.filter(el => el.id !== action.dataId);
      return { ...state, data: removedData };
    }
    case actionTypes.ADD_DATA: {
      const addData = [...state.data];
      addData.push(action.dataValue);
      return { ...state, data: addData };
    }
    case actionTypes.PATCH_DATA: {
      const patchData = state.data.map(el => (el.id === action.dataId ? action.dataValue : el));
      return { ...state, data: patchData };
    }
    case actionTypes.SET_REGIONS:
      return { ...state, regions: action.regions };
    case actionTypes.SET_USERS:
      return { ...state, users: action.users };
    case actionTypes.SET_STRATEGIC_PROJECTS:
      return { ...state, strategicProjects: action.projects };
    case actionTypes.SET_SUBREGIONS:
      return { ...state, subregions: action.subregions };
    case actionTypes.SET_COUNTRIES:
      return { ...state, countries: action.countries };
    case actionTypes.SET_ALL_BRANDS:
      return { ...state, allBrands: action.brands };
    case actionTypes.SET_BRANDS:
      return { ...state, brands: action.brands };
    case actionTypes.SET_APPLICATIONS:
      return { ...state, applications: action.applications };
    case actionTypes.SET_TEAMLEADS:
      return { ...state, teamLeads: action.teamLeads };
    case actionTypes.SET_COMPETITORS:
      return { ...state, competitors: action.competitors };
    case actionTypes.SET_PROJECT_STATUSES:
      return { ...state, projectStatuses: action.projectStatuses };
    case actionTypes.SET_STRATEGIC_PROJECT_STATUSES:
      return { ...state, strategicProjectStatuses: action.strategicProjectStatuses };
    case actionTypes.SET_PRIORITIES:
      return { ...state, priorities: action.priorities };
    case actionTypes.SET_ENTITIES:
      return { ...state, entities: action.entities };
    case actionTypes.SET_CUSTOMER_SEGMENTATIONS:
      return { ...state, customerSegmentations: action.customerSegmentations };
    case actionTypes.SET_STRATEGIC_PROJECT_TYPES:
      return { ...state, strategicProjectTypes: action.strategicProjectTypes };
    case actionTypes.SET_STATUS_REASONS:
      return { ...state, statusReasons: action.statusReasons };
    case actionTypes.SET_CLASSIFICATIONS:
      return { ...state, classifications: action.classifications };
    case actionTypes.SET_BUSINESS_UNITS:
      return { ...state, businessUnits: action.businessUnits };
    case actionTypes.SET_FIELDS:
      return { ...state, fields: action.fields };
    case actionTypes.SET_PREDEFINED_TASKS:
      return { ...state, predefinedTasks: action.tasks };
    case actionTypes.SET_PROJECTS:
      return { ...state, projects: action.projects };
    case actionTypes.SET_STRATEGIC_PROJECTS_PM:
      return { ...state, strategicProjectsPm: action.projects };
    case actionTypes.SELECT_CHANGE: {
      const currentSelectValues = { ...state.selectValues };
      currentSelectValues[action.key] = action.value;
      return { ...state, selectValues: currentSelectValues };
    }
    case actionTypes.EDIT_CHANGE: {
      const currentEditValues = { ...state.editValues };
      currentEditValues[action.key] = action.value;
      return { ...state, editValues: currentEditValues };
    }
    default:
      return state;
  }
};
