export const SELECT_CHANGE = 'SELECT_PROJECT_CHANGE';
export const SELECT_SUBARRAY_CHANGE = 'SELECT_PROJECT_SUBARRAY_CHANGE';
export const RESET_FORM = 'RESET_PROJECT_FORM';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT = 'SET_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const SET_COUNT = 'SET_PROJECT_COUNT';
export const SET_PAGE = 'SET_PROJECT_PAGE';
export const SET_SEARCH = 'SET_PROJECT_SEARCH';
export const SET_REMARKS = 'SET_PROJECT_REMARKS';
export const ADD_REMARK = 'ADD_REMARK';
export const EDIT_REMARK = 'EDIT_REMARK';
export const REMOVE_REMARK = 'REMOVE_REMARK';
export const SET_INITIATOR = 'SET_PROJECT_INITIATOR';
export const SET_FILES = 'SET_PROJECT_FILES';
export const RESET_FILES = 'RESET_PROJECT_FILE';
export const REMOVE_FILE = 'REMOVE_PROJECT_FILE';
export const ADD_FILE = 'ADD_PROJECT_FILE';
export const SET_TODOS = 'SET_OPERATIVE_TODOS';
export const CHANGE_TODO = 'CHANGE_OPERATIVE_TODO';
export const ADD_TODO = 'ADD_OPERATIVE_TODO';
export const EDIT_TODO = 'EDIT_OPERATIVE_TODO';
export const REMOVE_TODO = 'REMOVE_OPERATIVE_TODO';
export const SET_FILTER = 'SET_FILTER';
export const SET_SORT = 'SET_SORT';
